<template>
  <div class="term-content">
    <div class="section">
      <p>
        Bem-vindo ao <b>Centro Universitário Instituto Mix!</b>
      </p>
    </div>

    <div class="section">
      <p>
        É com enorme satisfação que damos as boas-vindas a você, que está prestes a iniciar
        uma nova e transformadora jornada educacional. O Instituto Mix de Prossões sempre foi
        referência em educação, oferecendo cursos prossionalizantes que já impactaram a vida
        de milhares de pessoas em todo o Brasil. Agora, demos um passo além: nos tornamos o
        <b>Centro Universitário Instituto Mix</b>, ampliando nossas possibilidades de ensino e
        oferecendo mais de 100 cursos de graduação na modalidade EAD.
      </p>
    </div>

    <div class="section">
      <h1 class="subtitle">1. SOBRE NÓS</h1>

      <p>
        Com uma trajetória marcada pela excelência no segmento de cursos prossionalizantes e
        mais de 600 escolas espalhadas pelo Brasil, o Instituto Mix de Prossões é uma marca
        consolidada e de renome nacional. Nossa missão sempre foi transformar vidas por meio
        da educação, e agora, como Centro Universitário, estamos ainda mais preparados para
        atender aos anseios de nossos alunos, colaboradores e parceiros.
      </p>

      <p>
        Investimos continuamente em talentos e tecnologias para garantir a melhor experiência
        educacional, buscando superar expectativas e proporcionar um aprendizado de
        qualidade que acompanhe as demandas do mercado e da sociedade.
      </p>

      <p>
        <b>
          Este documento apresenta os Termos de Uso para a contratação de nossos cursos de
          graduação em EAD. Aqui, você terá acesso às regras, requisitos e todas as informações
          necessárias para garantir que sua experiência conosco seja clara, segura e enriquecedora.
        </b>
      </p>

      <p>Você está pronto para transformar a sua vida? Então, vamos juntos!</p>
    </div>

    <div class="section">
      <h1 class="subtitle">1. DA ACEITAÇÃO</h1>

      <p>
        O presente Termo estabelece obrigações contratadas de livre e espontânea vontade,
        entre <b>Centro Universitário Instituto Mix</b> e os usuários da plataforma ou do aplicativo.
      </p>

      <p>
        Ao utilizar a plataforma o usuário aceita integralmente as presentes normas e se
        compromete a observá-las, sob o risco de aplicação das penalidades cabíveis.
      </p>

      <p>
        A aceitação dos termos é fundamental para o acesso e para utilização de quaisquer
        serviços fornecidos. Caso não concorde com as disposições deste instrumento, o usuário
        não deve utilizá-los.
      </p>
    </div>

    <div class="section">
      <h1 class="subtitle">2. DO CADASTRO E ACESSO</h1>

      <p>
        O acesso à página institucional do <b>Centro Universitário Instituto Mix</b> é totalmente livre e
        pode ser realizado por qualquer usuário que esteja navegando pelo site. Nessa página,
        você poderá explorar os cursos oferecidos, conhecer mais sobre nossa instituição e
        vericar informações preliminares relacionadas à graduação.
      </p>

      <p>
        Para dar início ao seu processo de ingresso, é necessário realizar o cadastro de
        pré-matrícula diretamente em nossa página. Nesse momento, solicitaremos algumas
        informações pessoais, como nome, e-mail, telefone e outros dados indispensáveis para
        que nossos atendentes possam entrar em contato e auxiliá-lo com o próximo passo de
        sua jornada acadêmica.
      </p>

      <p>
        Para se matricular em um dos cursos de graduação EAD do Centro Universitário Instituto
        Mix, é necessário atender aos seguintes requisitos:
      </p>

      <ul>
        <li>
          <p>
            <b>Ensino médio completo:</b> comprovado mediante apresentação do certicado de
            conclusão.
          </p>
        </li>

        <li>
          <p>
            <b>Menores de idade:</b> devem ser representados por seus pais ou responsáveis legais,
            que assinarão o contrato em nome do aluno.
          </p>
        </li>
      </ul>

      <p>
        Os cursos de graduação do Centro Universitário Instituto Mix oferecem diversas formas
        de ingresso para atender às diferentes necessidades e pers de nossos alunos:
      </p>

      <ul>
        <li>
          <p>
            <b>Vestibular:</b> Realização do processo seletivo conforme edital divulgado.
          </p>
        </li>

        <li>
          <p>
            <b>Transferência:</b> Para alunos oriundos de outras instituições de ensino, mediante
            apresentação do histórico escolar.
          </p>
        </li>

        <li>
          <p>
            <b>Segunda Graduação:</b> Disponível para quem já concluiu uma graduação e deseja
            iniciar outra, com comprovação por diploma e histórico escolar.
          </p>
        </li>

        <li>
          <p>
            <b>ENEM:</b> Utilização da nota do Exame Nacional do Ensino Médio (ENEM) como
            critério de ingresso.
          </p>
        </li>
      </ul>

      <p>
        Cada curso possui suas especicidades, incluindo conteúdo programático, metodologia e
        valores de investimento. Essas informações estão detalhadas em nossa página e também
        podem ser esclarecidas diretamente pelos atendentes no momento do contato. Estamos
        prontos para ajudá-lo a escolher a melhor opção para suas metas educacionais e
        prossionais.
      </p>

      <p>
        Com o processo de ingresso completo e realizado com sucesso você será ocialmente
        um aluno de graduação do <b>Centro Universitário Instituto Mix</b>. O usuário se torna nosso
        aluno, e possuirá um login e uma senha pessoal, onde lhe é assegurado o acesso
        individual à plataforma de ensino. Desta forma compete ao usuário, exclusivamente, a
        manutenção da referida de maneira condencial e segura, evitando o acesso indevido às
        informações pessoais.
      </p>

      <p>
        Fica vedado ao usuário, sob pena de imediato cancelamento da matrícula, sem direito à
        devolução de valores e sem prejuízo das demais medidas cabíveis no âmbito civil e
        criminal, efetuar as seguintes práticas:
      </p>

      <div class="sub">
        <p>
          a) Compartilhar seu login e senha com terceiros;
        </p>

        <p>
          b) Adquirir o curso em regime de “group buy” ou outra forma de rateio onde várias
          pessoas adquirem o acesso através do nome de uma única pessoa
          compartilhando-o entre si;
        </p>

        <p>
          c) Fazer uso de ferramentas para gravar o áudio dos vídeos ou para de qualquer
          forma fazer o download dos áudios não disponibilizados especicamente para
          esta nalidade, ainda que para uso exclusivo pessoal;
        </p>
      </div>

      <p>
        Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do
        usuário, que deverá informar prontamente a plataforma em caso de uso indevido do
        respectivo acesso.
      </p>

      <p>
        Cabe ao usuário assegurar que o seu equipamento seja compatível com as características
        técnicas que viabilizam a utilização da plataforma e dos serviços ou produtos.
      </p>
    </div>

    <div class="section">
      <h1 class="subtitle">3. DOS SERVIÇOS EDUCACIONAIS</h1>

      <p>
        Os serviços educacionais do <b>Centro Universitário Instituto Mix</b> oferecem cursos de
        graduação na modalidade EAD (Ensino a Distância), assegurando uma formação de
        qualidade, regularizada e alinhada às diretrizes do Ministério da Educação (MEC).
      </p>

      <p>
        A plataforma de ensino virtual disponibiliza ferramentas e funcionalidades especícas
        para otimizar a experiência do aluno durante sua jornada acadêmica.
      </p>

      <ul>
        <li>
          <p>
            Os cursos são apresentados com clareza, incluindo informações detalhadas sobre
            conteúdo, carga horária, valores e requisitos especícos, garantindo uma
            contratação consciente e informada.
          </p>
        </li>

        <li>
          <p>
            As aulas são acessíveis por meio de tecnologia de streaming, disponíveis <b>24 horas
            por dia, 7 dias por semana</b>, salvo em casos de força maior ou imprevistos
            técnicos.
          </p>
        </li>

        <li>
          <p>
            É obrigatório o cumprimento de <b>75% (setenta e cinco por cento) de frequência</b> em
            cada disciplina.
          </p>
        </li>

        <li>
          <p>
            Para aprovação, o aluno deve alcançar uma <b>média mínima de 5,0 (cinco)</b> nas
            avaliações realizadas durante o curso.
          </p>
        </li>

        <li>
          <p>
            O aluno deve cumprir integralmente a carga horária exigida para atividades
            complementares e projetos de extensão, conforme a matriz curricular do curso.
          </p>
        </li>


        <li>
          <p>
            O TCC é obrigatório apenas para cursos de <b>Bacharelado e Licenciatura</b>, devendo
            ser realizado conforme as orientações da coordenação acadêmica.
          </p>
        </li>

        <li>
          <p>
            Os cursos que possuem <b>estágio obrigatório</b> ou outras atividades curriculares
            especícas devem ser concluídos de acordo com os critérios denidos pela
            matriz curricular e supervisionados pela instituição.
          </p>
        </li>

        <li>
          <p>
            Para a emissão do certicado de conclusão, é indispensável o cumprimento de
            todos os requisitos acadêmicos, incluindo frequência mínima, média exigida,
            atividades complementares e, quando aplicável, o TCC e estágio curricular.
          </p>
        </li>
      </ul>

      <p>
        No ato da matrícula, solicitamos dados pessoais, como CPF, endereço de cobrança e
        informações de pagamento, histórico escolar. O aluno, ao fornecer esses dados, autoriza
        as cobranças conforme a forma de pagamento escolhida e os valores informados.
      </p>

      <p>
        O aluno é responsável pelas informações fornecidas no momento do cadastro e
        matrícula, garantindo a veracidade e a entrega dos documentos comprobatórios
        solicitados.
      </p>

      <p>
        O descumprimento das regras ou a não apresentação da documentação poderá acarretar
        a extinção do contrato, isentando o Centro Universitário Instituto Mix de quaisquer
        responsabilidades decorrentes do cancelamento.
      </p>
    </div>

    <div class="section">
      <h1 class="subtitle">4. DAS RESPONSABILIDADES</h1>

      <p>
        É de responsabilidade do usuário:
      </p>

      <div class="sub">
        <p>
          a) Defeitos ou vícios técnicos originados no próprio sistema do usuário;
        </p>

        <p>
          b) A correta utilização da plataforma, dos serviços ofertados, prezando pela boa
          convivência, pelo respeito e cordialidade entre os usuários;
        </p>

        <p>
          c) Pelo cumprimento e respeito ao conjunto de regras disposto neste Termo de Uso,
          na Política de Privacidade e nas legislações vigentes;
        </p>

        <p>
          d) Pela proteção aos dados de acesso à sua conta (login e senha);
        </p>

        <p>
          e) Veracidade das informações prestadas;
        </p>
      </div>

      <p>
        É de responsabilidade da plataforma:
      </p>

      <div class="sub">
        <p>
          a) Indicar as características dos serviços ofertados;
        </p>

        <p>
          b) Defeitos e Vícios encontrados nos serviços ofertados, desde que tenha lhe dado
          causa;
        </p>

        <p>
          c) Conteúdos e atividades lícitas originadas da plataforma;
        </p>
      </div>

      <p>
        A plataforma não se responsabiliza por links externos contidos em seu sistema que
        possam redirecionar o usuário ao ambiente externo a sua rede.
      </p>
    </div>

    <div class="section">
      <h1 class="subtitle">5. DA PROTEÇÃO DE DADOS</h1>

      <p>
        O usuário por livre manifestação de vontade, consente e concorda, que o <b>INSTITUTO MIX DE PROFISSÕES</b>
        tenha acesso aos dados coletados no momento do cadastro, conforme dispõe a LGPD – Lei Geral de Proteção de Dados, sendo:
      </p>

      <table border="1">
        <thead>
          <tr>
            <th>NATUREZA DOS DADOS</th>
            <th>DADOS PESSOAIS</th>
            <th>FINALIDADE DO USO</th>
            <th>BASE LEGAL</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="5">Cadastrais</td>
            <td rowspan="5">Nome completo, CPF, Data de Nascimento, Endereço completo, E-mail, Telefone, Histórico escola</td>
            <td>Prestação dos serviços educacionais e gestão administrativa</td>
            <td>Execução de Contrato</td>
          </tr>
          <tr>
            <td>Oferta de cursos e serviços</td>
            <td>Consentimento</td>
          </tr>
          <tr>
            <td>Cumprir com possíveis exigências legais, se necessário</td>
            <td>Cumprimento de Obrigação legal ou regulatória</td>
          </tr>
          <tr>
            <td>Procedimentos financeiros</td>
            <td>
              Execução de contrato
              <br>
              Proteção ao crédito
            </td>
          </tr>
          <tr>
            <td>Armazenamento pelo período prescricional</td>
            <td>Exercício regular do direito</td>
          </tr>
        </tbody>
      </table>

      <p>
        O usuário autoriza o compartilhamento dos seus dados pessoais com agentes de
        tratamento de dados internos, caso seja necessário, para as nalidades listadas e gestão
        administrativa, observado os princípios e as garantias estabelecidas pela LGPD.
      </p>

      <p>
        O usuário e o Centro Universitário devem tomar as medidas cabíveis e aplicar as
        medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de
        acessos não autorizados ou de qualquer forma de tratamento inadequado ou ilícito, com
        o objetivo de assegurar o nível de segurança devido aos dados pessoais decorrentes
        deste CONTRATO.
      </p>

      <p>
        Os dados pessoais do usuário serão armazenados por todo o período contratualmente
        rmado para as nalidades relacionadas neste instrumento e ainda após o término da
        contratação para cumprimento de obrigação legal ou impostas por órgãos de scalização,
        nos termos do artigo 16 da Lei n° 13.709/2018.
      </p>

      <p>
        Qualquer controvérsia ou incidente envolvendo dados pessoais deverá ser solucionada
        através de conciliação direta entre a plataforma e o usuário, a ser realizada de modo físico
        ou online junto ao encarregado de dados da instituição.
      </p>
    </div>

    <div class="section">
      <h1 class="subtitle">6. DA PROPRIEDADE INTELECTUAL</h1>

      <p>
        O presente Termo em conjunto com a devida contratação concede aos usuários uma
        licença não exclusiva, não transferível e não sublicenciável, para acessar e fazer uso da
        plataforma e dos serviços educacionais por ela disponibilizados.
      </p>

      <p>
        A estrutura do site ou aplicativo, as marcas, logotipo, nomes comerciais, gráco e design
        de interface, imagens, ilustrações, fotograas, apresentações, vídeos, conteúdos escritos
        e de som e áudio, programas de computador, banco de dados, arquivos de transmissão e
        quaisquer outras informações e direitos de propriedade intelectual do <b>Centro
        Universitário IM</b>, observados os termos da legislação aplicável estão devidamente
        reservados.
      </p>

      <p>
        Este Termo de Condições de Uso não cede ou transfere ao usuário qualquer direito, de
        modo que o acesso não gera qualquer direito de propriedade intelectual ao usuário,
        exceto pela licença limitada ora concedida.
      </p>
    </div>

    <div class="section">
      <h1 class="subtitle">7. DAS SANÇÕES</h1>

      <p>
        Sem prejuízo das demais medidas legais cabíveis, o <b>Centro Universitário Instituto Mix</b>
        poderá a qualquer momento, advertir, suspender ou cancelar a conta do usuário:
      </p>

      <div class="sub">
        <p>
          a) Que violar qualquer dispositivo do presente Termo;
        </p>

        <p>
          b) Que descumprir os seus deveres de usuário;
        </p>

        <p>
          c) Que tiver qualquer comportamento fraudulento, doloso ou que ofenda a terceiros;
        </p>
      </div>
    </div>

    <div class="section">
      <h1 class="subtitle">8. DA RESCISÃO</h1>

      <p>
        A não observância das obrigações pactuada neste Termo de Condições de Uso ou da
        Legislação aplicável poderá, sem aviso prévio, ensejar a imediata rescisão unilateral por
        parte do <b>INSTITUTO MIX DE PROFISSÕES</b> e o bloqueio de todos os serviços prestados ao
        usuário.
      </p>

      <p>
        Em caso de trancamento ou rescisão de contrato ativo, o aluno deverá entrar em contato
        com o setor administrativo correspondente.
      </p>
    </div>

    <div class="section">
      <h1 class="subtitle">9. DAS ALTERAÇÕES</h1>

      <p>
        Os itens descritos no presente instrumento poderão sofrer alterações, unilateralmente e a
        qualquer tempo, por parte do <b>INSTITUTO MIX DE PROFISSÕES</b>, para adequar ou
        modicar os serviços ofertados, bem como para atender novas exigências legais. Sem
        prejuízo daqueles que já utilizam algum serviço.
      </p>
    </div>

    <div class="section">
      <h1 class="subtitle">10. DO CONTRATO</h1>

      <p>
        Este documento constitui um conjunto de <b>regras prévias e orientações gerais</b>,
        congurando-se como um <b>pré-contrato</b>, com o objetivo de esclarecer as condições e os
        requisitos relacionados à contratação dos cursos de graduação EAD oferecidos pelo
        <b>Centro Universitário Instituto Mix</b>.
      </p>

      <p>
        Embora este termo forneça informações preliminares e essenciais, o contrato formal
        prevalecerá em casos de dúvidas ou divergências, servindo como documento ocial que
        regula a relação jurídica entre o aluno e o <b>Centro Universitário Instituto Mix</b>.
      </p>

      <p>
        Recomendamos que o aluno consulte regularmente seu contrato formal na área do aluno
        e entre em contato com a coordenação ou suporte administrativo em caso de dúvidas ou
        esclarecimentos.
      </p>
    </div>

    <div class="section">
      <h1 class="subtitle">11. DO FORO</h1>

      <p>
        Para a solução de controvérsias decorrentes do presente instrumento será aplicado
        integralmente o Direito brasileiro.
      </p>

      <p>
        Os eventuais litígios deverão ser apresentados na plataforma ocial consumidor.gov.br,
        como mecanismo prioritário para dirimir dúvidas e conitos do presente contrato, e, em
        não havendo solução por qualquer das partes, elegem, então, o foro da comarca de
        Araranguá/SC como o competente para a solução dos demais litígios remanescentes.
      </p>

      <p>
        Última atualização em 21.01.2025
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnimTermComponent',
};
</script>

<style lang="scss" scoped>
  .term-content {
    overflow-y: auto;
    height: 55vh;

    .section {
      margin-top: 1rem;
      padding: 0 1rem;

      .sub {
        margin-left: 1.5rem;
      }

      .subtitle {
        font-size: 16px;
        margin-bottom: 0.5rem;
      }

      a {
        color: #3182CE;
      }

      p {
        text-align: justify;
        font-size: 12px;
        margin-bottom: 0.5rem;
      }

      ul {
        margin-left: 2.5rem;
      }

      table {
        width: 100%;
        font-size: 10px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-collapse: collapse;
      }

      thead th {
        background-color: #D9D9D9;
        padding: 8px;
        text-align: left;
      }

      tbody td {
        padding: 8px;
        border: 1px solid #000;
        text-align: center;
      }
    }
  }
</style>