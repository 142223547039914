import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login';
import New from '@/views/New';
import SignDigitalContract from '@/views/SignDigitalContract';
import NewSuccess from '@/views/NewSuccess';
import Checkout from '@/views/Checkout';
import ContractAction from '@/views/ContractAction';
import AcceptTerms from '@/views/AcceptTerms';
import NotFound from '@/views/NotFound';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      title: 'Entrar',
      requiresAuth: false
    }
  },
  {
    path: '/precontract/new',
    name: 'new',
    component: New,
    meta: {
      title: 'Novo contrato',
      requiresAuth: true
    }
  },
  {
    path: '/precontract/success',
    name: 'newSuccess',
    component: NewSuccess,
    meta: {
      title: 'Sucesso',
      requiresAuth: true
    }
  },
  {
    path: '/precontract/:contractId/contract/sign',
    name: 'signDigitalContract',
    component: SignDigitalContract,
    meta: {
      title: 'Assinar contrato',
      requiresAuth: false
    }
  },
  {
    path: '/precontract/:contractId/checkout/pay',
    name: 'checkout',
    component: Checkout,
    meta: {
      title: 'Pagar pré-contrato',
      requiresAuth: false
    }
  },
  {
    path: '/precontract/:contractId',
    name: 'contract',
    component: ContractAction,
    meta: {
      title: 'Detalhes do pré-contrato',
      requiresAuth: false
    }
  },
  {
    path: '/precontract/:contractId/accept/terms',
    name: 'acceptTerms',
    component: AcceptTerms,
    meta: {
      title: 'Termos e condições de uso',
      requiresAuth: false
    }
  },
  {
    path: '*',
    name: 'notfound',
    component: NotFound,
    meta: {
      title: 'Página não encontrada'
    }
  }
];

const base = window.location.pathname.startsWith('/grad') ? '/grad' : '/';
const router = new VueRouter({
  mode: 'history',
  base: base,
  routes
});

router.beforeEach((to, from, next) => {
  const hasToken = !!localStorage.getItem('token');
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  const isGrad = window.location.pathname.startsWith('/grad');
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} | ${isGrad ? 'Centro universitário Insituto Mix' : 'Instituto Mix'}`;
  }

  if (requiresAuth && !hasToken && from.name != 'login') next('/');
  else if (hasToken && to.name == 'login') next('/precontract/new');
  else next();
});

export default router;