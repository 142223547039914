import '@/services/rules';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store/index.js';
import vco from "v-click-outside";
import Axios from 'axios';
import VueTheMask from 'vue-the-mask';
import Ripple from 'vue-ripple-directive';
import Service from '@/services/service';
import VCalendar from 'v-calendar';
import VueClipboard from 'vue-clipboard2';
import ptBR from 'vee-validate/dist/locale/pt_BR.json';
import VueMeta from 'vue-meta';
import { extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import * as Sentry from "@sentry/vue";

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('pt_br', ptBR);

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(VueTheMask);
Vue.use(VCalendar);
Vue.use(VueClipboard);
Vue.use(vco);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['pagamento.institutomix.com.br', /^\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

Vue.directive('ripple', Ripple);

Vue.prototype.$http = Axios;
Vue.prototype.$service = Service;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');