<template>
  <header>
    <div class="logo-container">
      <img
        :src="logoImport"
        title="Logo empresa"
        alt="Logo empresa"
      >
    </div>

    <div v-if="isAuth" class="button-container">
      <button-component
        v-if="isAdmin && !isDashboardRoute && !getIsExternalAuth"
        v-ripple="'rgba(255, 255, 255, 0.2)'"
        styles="secondary"
        iconLeft="icon-align"
        buttonTextColor="#FFFFFF"
        buttonHoverColor="rgba(255, 255, 255, 0.1)"
        text="Dashboard"
        @click.native="toDashboard"
      />

      <button-component
        v-if="!isNewContractRoute && !getIsExternalAuth"
        v-ripple="'rgba(255, 255, 255, 0.2)'"
        styles="secondary"
        iconLeft="icon-add"
        buttonTextColor="#FFFFFF"
        buttonHoverColor="rgba(255, 255, 255, 0.1)"
        text="Novo contrato"
        @click.native="toNewContract"
      />

      <button-component
        v-if="!getIsExternalAuth"
        v-ripple="'rgba(255, 255, 255, 0.2)'"
        styles="secondary"
        iconLeft="icon-logout"
        buttonTextColor="#FFFFFF"
        buttonHoverColor="rgba(255, 255, 255, 0.1)"
        text="Sair"
        @click.native="logout"
      />
    </div>
  </header>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Button from '@/components/Button';

  export default {
    name: 'HeaderComponent',

    props: {
      logoPath: {
        type: String,
        default: 'logo-white.png'
      }
    },

    components: {
      'button-component': Button
    },

    data() {
      return {
        isAuth: false,
        isAdmin: false,
        isNewContractRoute: false,
        isDashboardRoute: false
      };
    },

    computed: {
      ...mapGetters({
        getCollaboratorId: 'getCollaboratorId',
        getIsExternalAuth: 'authModule/getIsExternalAuth'
      }),

      logoImport() {
        return require(`@/assets/images/${this.logoPath}`);
      }
    },

    created() {
      this.isAuth = !!localStorage.getItem('token');
      this.isAdmin = this.getCollaboratorId == 248;

      this.isNewContractRoute = this.$route.name == 'new';
      this.isDashboardRoute = this.$route.name == 'dashboard';
    },

    methods: {
      toNewContract() {
        this.$router.push('/precontract/new');
      },

      toDashboard() {
        this.$router.push('/dashboard');
      },

      logout() {
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/');
          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  header {
    z-index: 1;
    background: $main-color;
    padding: 1rem 3rem;
    font-size: 14px;
    color: #FFF;
    display: flex;

    .logo-container {
      margin-right: 2rem;
      display: flex;
      align-items: center;

      img {
        width: 12rem;
        cursor: pointer;
      }
    }

    .button-container {
      display: flex;
      align-items: center;
      margin-left: auto;
      cursor: pointer;
      font-weight: 600;
      gap: 1rem;

      i {
        margin-right: 0.5rem;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 1045px) {
    header {
      padding: 1rem;

      .logo-container {
        position: relative;
      }

      .text-container {
        display: none;
      }

      .logout {
        width: 100%;
        margin-right: 0.5rem;
        justify-content: flex-end;
      }
    }
  }

  @media (max-width: 520px) {
    header {
      padding: 0.5rem;
      flex-direction: column;

      .logo-container {
        margin-right: 0;
        justify-content: center;

        img {
          width: 9rem;
        }
      }

      .button-container {
        margin-top: 0.5rem;
        justify-content: center;
        margin-left: unset;
      }
    }
  }
</style>